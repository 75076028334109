function sendLogToServer(message: string) {
  fetch('/api/client-log', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ message }),
  });
}

export function initializeLogCollector() {
  const originalConsoleLog = console.log;

  console.log = function (...args: any[]) {
    originalConsoleLog.apply(console, args);
    const logMessage = args.join(' ');
    sendLogToServer(JSON.stringify(logMessage));
  };
}
