"use client"
import * as React from 'react';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v14-appRouter';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from '@/theme';
import { SnackbarKey, SnackbarProvider, useSnackbar } from 'notistack';
import { InfoFiadoProvider } from '@/context/infoFiadoContext';
import { SessionProvider, signIn, signOut, useSession } from "next-auth/react";
import './globals.css';
import { CurrentSessionProvider } from '@/context/currentSessionContext';
import {initializeLogCollector} from '@/utils/logCollector';
import IconButton from '@mui/material/IconButton';
import CloseIcon from "@mui/icons-material/Close";

const SnackbarAction: React.FC<{ key: SnackbarKey }> = ({ key }) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton
      aria-label="close"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
    <CloseIcon sx={{height: 20, width:20}} />
    </IconButton>
  );
};

export default function RootLayout(props: { children: React.ReactNode }) {

  React.useEffect(() => {
    initializeLogCollector();
  }, []);

  return (

    <html lang="en">
      <body>
      <CurrentSessionProvider >
        <AppRouterCacheProvider options={{ enableCssLayer: true }}>
          <ThemeProvider theme={theme}>
              <InfoFiadoProvider>
                <SessionProvider>
                  <CssBaseline />

                  <SnackbarProvider maxSnack={3}
                    autoHideDuration={null}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    action={(key: SnackbarKey) => <SnackbarAction key={key} />}
                  >
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}

                    {props.children}

                  </SnackbarProvider>
                </SessionProvider>

              </InfoFiadoProvider>
          </ThemeProvider>
        </AppRouterCacheProvider>
        </CurrentSessionProvider>
      </body>
    </html>
  );
}
